import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import styles from './OrgHsse.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const HsseKpiGraph1 = (props) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        let xTicks = ['Retail', 'LPG', 'Lubes', 'Aviation']
        
        if (chartInstance) {
            chartInstance.destroy()
        }
        let esaClosure = []
        let isaClosure = []
        let capaCompliance = []
        

        props.hsseData.forEach(hsseValues => {
            if(hsseValues.kpi_name === 'Pending Recommendation ESA'){
                if(hsseValues.bu === 'RETAIL'){
                    esaClosure[0] = parseFloat(hsseValues.actual).toFixed(2)
                }
                if(hsseValues.bu === 'LPG'){
                    esaClosure[1] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'LUBRICANTS'){
                    esaClosure[2] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'AVIATION'){
                    esaClosure[3] = parseFloat(hsseValues.actual).toFixed(2)
                // }if(hsseValues.bu === 'PIPELINE'){
                //     esaClosure[4] = parseFloat(hsseValues.actual).toFixed(2)
                }
                // if(hsseValues.bu === 'I&C'){
                //     esaClosure[4] = parseFloat(hsseValues.actual).toFixed(2)
                // }
                    // maxvalue.push(hsseValues.actual)
            }
            if(hsseValues.kpi_name === 'Pending Recommendation ISA'){
                if(hsseValues.bu === 'RETAIL'){
                    isaClosure[0] = parseFloat(hsseValues.actual).toFixed(2)
                }
                if(hsseValues.bu === 'LPG'){
                    isaClosure[1] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'LUBRICANTS'){
                    isaClosure[2] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'AVIATION'){
                    isaClosure[3] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'PIPELINE'){
                    isaClosure[4] = parseFloat(hsseValues.actual).toFixed(2)
                }
                // if(hsseValues.bu === 'I&C'){
                //     isaClosure[5] = parseFloat(hsseValues.actual).toFixed(2)
                // }
            }
            if(hsseValues.kpi_name === 'CAPA Compliance'){
                if(hsseValues.bu === 'RETAIL'){
                    capaCompliance[0] = parseFloat(hsseValues.actual).toFixed(2)
                }
                if(hsseValues.bu === 'LPG'){
                    capaCompliance[1] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'LUBRICANTS'){
                    capaCompliance[2] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'AVIATION'){
                    capaCompliance[3] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'PIPELINE'){
                    capaCompliance[4] = parseFloat(hsseValues.actual).toFixed(2)
                }
                // if(hsseValues.bu === 'I&C'){
                //     capaCompliance[5] = parseFloat(hsseValues.actual).toFixed(2)
                // }
            }
        })

        const ctx = chartContainer.current.getContext('2d')
        let gradient = ctx.createLinearGradient(0, 0, 0, 450)
        gradient.addColorStop(1, 'rgb(4,202,166)')
        gradient.addColorStop(0, 'rgba(0, 224, 188)')
        let gradient2 = ctx.createLinearGradient(0, 0, 0, 450)
        gradient2.addColorStop(0, '#2281e8')
        gradient2.addColorStop(1, '#2278e8')
        let gradient3 = ctx.createLinearGradient(0, 0, 0, 450)
        gradient3.addColorStop(1, '#7248bd')
        gradient3.addColorStop(0, '#6e41bf')

        const chartConfig = {
            type: 'bar',
            data: {
                labels: xTicks,
                datasets: [
                    {
                        label: 'Pending Recommendation ESA',
                        data: esaClosure,
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: gradient,
                        borderWidth: 0,
                        datalabels: {
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#ffffff',
                            font: {
                                size: 14
                            },
                            formatter: function (value) {
                                return (value != 0) ? value : ''
                            }
                        },
                        order : 2
                    },
                    {
                        label: 'Pending Recommendation ISA',
                        data: isaClosure,
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: gradient2,
                        borderWidth: 0,
                        datalabels: {
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#ffffff',
                            font: {
                                size: 14
                            },
                            formatter: function (value) {
                                return (value != 0) ? value : ''
                            }
                        },
                        order : 2
                    },
                    {
                        label: 'CAPA Compliance',
                        data: capaCompliance,
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: gradient3,
                        borderWidth: 0,
                        datalabels: {
                            display: true,
                            anchor: 'center',
                            align: 'center',
                            color: '#ffffff',
                            font: {
                                size: 14
                            },
                            formatter: function (value) {
                                return (value != 0) ? value : ''
                            }
                        },
                        order : 2
                    },
                ]
            },
            options: {
                    plugins: {
                       datalabels: {
                          display: true,
                          align: 'center',
                          anchor: 'center'
                       }
                    },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                annotation: {
                },
                elements : {
                    line : {
                        tension : 0.4
                    }
                },
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            type: 'category',
                            display: true,
                            offset: true,
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                                lineWidth: 3,
                                zeroLineBorderDash: [3]
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            stacked: true,
                            ticks: {
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit : 5
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1,
                                lineWidth: 3,
                            },
                            scaleLabel: {
                                display: true,
                                // labelString: unit[0],
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ]
                },
                legend: {
                    display: false
                },
            }
        }
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [props.graphData, props.publicVar, props.privateVar, props.bothVar, props.hsseData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`flex-grow-1 ${styles.graphContainer}`}>
        <div
            className={`d-flex justify-content-between align-items-center ${styles.legendsContainer}`}
        >
                    <div
                        className={`d-flex align-items-center justify-content-around p-2 ${styles.tabLabelsContainer}`}
                    >
                        <div className={`d-flex align-items-center ${styles.lblSpace}`}>
                            <span className={`mr-3 ${styles.esaClosure}`}></span>ESA Closure &#40; % &#41;
                        </div>
                        <div className={`d-flex align-items-center ${styles.lblSpace}`}>
                            <span className={`mr-2 ${styles.isaClosure}`}></span>ISA Closure &#40; % &#41;
                        </div>
                        <div className={`d-flex align-items-center ${styles.lblSpace}`}>
                            <span className={`mr-2 ${styles.capaCompliance}`}></span>CAPA Compliance &#40; % &#41;
                        </div>
                    </div>            
        </div>
        <div className={`my-4 ${styles.grmGraph}`}>
            {/* <canvas ref={chartContainer} /> */}
            <canvas
            ref={chartContainer}
        />   
        </div>
    </div>
    )
}
export default HsseKpiGraph1