import React, { useEffect, useState, useCallback } from 'react'
import styles from './BuIncProductWiseSaleRevenue.module.scss'
import expandIcon from '../../../assets/images/expandIcon.png'
import ProductWiseBarGraph from '../BuIncHsse/ProductWiseBarGraph.js'
import { Spinner } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchupdatedStatus, fetchProductWiseIncSalesRevenue } from '../../../services/bu'
import { numberFormatEnIn, numberFormatNoSeparator } from '../../../utility/utility'
import { isEmpty, last } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"


const BuIncProductWiseSaleRevenue = (props) => {
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(false)
    const [salesGraphData1, setSalesGraphData1] = useState(null)
    const [revenueGraphData1, setRevenueGraphData1] = useState(null)
    const [matlGroups, setMatlGroups] = useState([])  // State to store material groups
    const [totalSalesRevenue, setTotalSalesRevenue] = useState({})
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "retail" && res.kpi_name === "Sales" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        function prepareGraphData(kpi_name, kpiName1) {
            fetchProductWiseIncSalesRevenue(kpiName1, props.year)
                .then(response => {
                    if (!isEmpty(response.data)) {  
                            const sortedData = response.data.sort((a, b) => b.sales_perc - a.sales_perc); 
                            const filteredData = sortedData.filter(item => item.sales_perc >= 1);
                            const salesPercData = filteredData.map(item => item.sales_perc.toFixed(2))
                            setSalesGraphData1(salesPercData)
                            setTotalSalesRevenue(filteredData[0])
                            const revenuePercData = filteredData.map(item => item.revenue_perc.toFixed(2))
                            setRevenueGraphData1(revenuePercData)
                        setIsNoDataAvailable(false)
                        const materialGroups = filteredData.map(item => item.matl_group).filter(Boolean); // Extracting matl_group values
                        setMatlGroups(materialGroups);  // Set material groups dynamically
                    } else {
                        setIsNoDataAvailable(true)
                    }
                })
                .catch(error => console.debug(error))
        }
        prepareGraphData('Productwise Sales', "productwiseSalesRevenue")
        prepareGraphData('Productwise Revenue', "productwiseSalesRevenue")
        
    }, [props.product, props.year])
    return (
        <div className={`${styles.salesRevenueContiner}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`pt-2 ${styles.headerContainer}`}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <span>Product Wise Sales & Revenue - Daily</span>
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                    </div>
                    <div className={'row pt-2'}>
                        <div className={`col-6 ${styles.headerRight}`}>
                            {
                                isNoDataAvailable ||
                                <> 
                                    <span>Sales: {numberFormatEnIn(totalSalesRevenue.total_sales || 0)} TKL</span>
                                    {/* <span className={'ml-4'} style={{ color: `${Math.sign(totalSales.total_sales) === 1 ? '#74ce12' : '#e13219'}` }}>{numberFormatNoSeparator(totalSales.total_sales)}</span> */}
                                </>
                            }
                        </div>
                        <div className={`col-6 ${styles.headerRight}`}>
                            {
                                isNoDataAvailable ||
                                <>
                                    <span>Revenue: &#8377; {numberFormatEnIn(totalSalesRevenue.total_revenue || 0)} Cr</span>
                                    {/* <span className={'ml-4'} style={{ color: `${Math.sign(totalRevenue.total_revenue) === 1 ? '#74ce12' : '#e13219'}` }} >{numberFormatNoSeparator(totalRevenue.total_revenue)}</span> */}
                                </>
                            }
                        </div>
                    </div>
                </div>
                {
                    isNoDataAvailable ?
                        <NoDataFound height={507} />
                        : (
                            <React.Fragment>
                                <div className={`row ${styles.salesRevenueGraphHeader}`}>
                                    <div className={'col-5 p-2'}>
                                        <div className='row justify-content-md-center'>
                                            <div className='d-flex mr-2 align-items-center'>Sales:</div>
                                            <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.salesActualDot}`}></span>Actual</div>
                                            {/* <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.salesTargetDot}`}></span>Target</div> */}
                                        </div>
                                    </div>
                                    <div style={{ width: 20 }}></div> {/* Spacer */}
                                    <div className={'col-6 p-2'}>
                                        <div className='row justify-content-md-center'>
                                            <div className='d-flex mr-2 align-items-center'>Revenue:</div>
                                            <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.revenueActualDot}`}></span>Actual</div>
                                            {/* <div className='d-flex mr-2 align-items-center'><span className={`mr-2 ${styles.revenueTargetDot}`}></span>Target</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className={`row ${styles.salesRevenueGraph}`}>
                                    <div className={`col-5 p-0 ${styles.leftDiv}`}>
                                        {
                                            salesGraphData1 ?
                                                <ProductWiseBarGraph datas={salesGraphData1} sales={true} productData={matlGroups}></ProductWiseBarGraph>
                                                :
                                                <Spinner animation='border' role='status'>
                                                    <span className='sr-only'>Loading...</span>
                                                </Spinner>
                                        }
                                    </div>
                                    <div className={`col-2 pl-0 pr-1 ${styles.centerDiv}`}>
                                        {matlGroups.map((group, index) => (
                                            <div key={index} className={`d-flex align-items-center justify-content-center ${styles.layoutDiv}`}>
                                                {/* {group} */}
                                                {group.replace(/_/g, ' ')}
                                            </div>
                                        ))}
                                    </div>
                                    <div className={`col-5 p-0 ${styles.rightDiv}`}>
                                        {
                                            revenueGraphData1 ?
                                                <ProductWiseBarGraph datas={revenueGraphData1} revenue={true} productData={matlGroups}></ProductWiseBarGraph>
                                                :
                                                <Spinner animation='border' role='status'>
                                                    <span className='sr-only'>Loading...</span>
                                                </Spinner>
                                        }
                                    </div>
                                    {/* <div className={`${styles.MsHsdContainer}`}>
                                        <div className={`${styles.HsdCircle}`}><span className={`${styles.Hsd}`}>{props.product.toLocaleUpperCase()}</span></div>
                                    </div> */}
                                </div>

                                <div className={`d-flex justify-content-end ${styles.expandIconContainer}`}>
                                    <img className={`${styles.expandIcon}`} src={expandIcon} alt='Expand Icon' id='fullSrv' data-fs='handleenter' onClick={fullScrn}></img>
                                </div>
                            </React.Fragment>
                        )
                }

            </FullScreen>
        </div>
    )
}
export default BuIncProductWiseSaleRevenue