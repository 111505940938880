import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import ErrorBoundary from '../../../../src/ErrorBoundary'

const ProductWiseBarGraph = (props) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)
    const [error, setError] = useState(null);

    useEffect(() => {
        let salesActualArray = []
        let revenueActualArray = []
        let targetArray = []
        let labelPosition = ''
        let labelAlign = ''
        let paddingRight = 0
        let paddingLeft = 0
        // props.datas.forEach(item => {
        //     salesActualArray.push(item.sales_perc);
        //     revenueActualArray.push(item.revenue_perc);
        // });

        try {
            function prepareGraphData(apiGraphData) {
                apiGraphData.forEach((item) => {
                    if (props.sales === true) {
                        // salesActualArray = [26, 16, 15, 11, 10, 6, 6, 4, 3, 2, 1, 0, 0, 0]
                        salesActualArray = apiGraphData
                        // targetArray.push((item.target ? parseFloat(item.target).toFixed(2) : '0') * -1)
                        labelPosition = 'start'
                        labelAlign = 'start'
                        paddingRight = 0
                        paddingLeft = 150
                    }
                    else if (props.revenue === true) {
                        // revenueActualArray = [41, 3, 12, 10, 8, 7, 1, 7, 5, 3, 2, 0, 0, 0]
                        revenueActualArray = apiGraphData
                        // targetArray.push((item.target ? parseFloat(item.target).toFixed(2) : '0'))
                        labelPosition = 'end'
                        labelAlign = 'end'
                        paddingRight = 60
                        paddingLeft = 0
                    }
                })
            }
            prepareGraphData(props.datas)
            if (chartInstance) {
                chartInstance.destroy()
            }

            const ctx = chartContainer.current.getContext('2d')

            // Create the gradient for sales bars
            const salesGradient = ctx.createLinearGradient(0, 0, 0, 400)
            salesGradient.addColorStop(0, 'rgba(0, 168, 236, 1)') // Blue
            salesGradient.addColorStop(1, 'rgba(0, 224, 188, 1)') // Greenish

            // Create the gradient for revenue bars
            const revenueGradient = ctx.createLinearGradient(0, 0, 0, 400)
            revenueGradient.addColorStop(0, 'rgba(32, 176, 16, 1)') // Dark green
            revenueGradient.addColorStop(1, 'rgba(48, 255, 24, 1)') // Light green

            const chartConfig = {
                type: 'horizontalBar',
                data: {
                    labels: props.productData,
                    datasets: [
                        {
                            yAxisID: 'bar-x-axis1',
                            categoryPercentage: 1,
                            barPercentage: 0.5,
                            label: 'Sales Actual',
                            backgroundColor: '#1f47ac', // Set the gradient for sales
                            data: salesActualArray.map((val) => -val), // Inverted sales bars for right to left
                            borderWidth: 0,
                            datalabels: {
                                anchor: labelPosition,
                                align: labelAlign,
                                color: '#dee5ef',
                                font: {
                                    size: 14
                                },
                                formatter: function (_, context) {
                                    return `${salesActualArray[context.dataIndex]}%` // Show positive value in data labels
                                }
                            },
                        },
                        {
                            yAxisID: 'bar-x-axis2',
                            categoryPercentage: 0.5,
                            label: 'Revenue Actual',
                            backgroundColor: '#fa9b0c', // Set the gradient for revenue
                            data: revenueActualArray, // Keep revenue bars positive for left to right
                            borderWidth: 0,
                            datalabels: {
                                anchor: labelPosition,
                                align: labelAlign,
                                color: '#dee5ef',
                                font: {
                                    size: 14
                                },
                                formatter: function (_, context) {
                                    return `${revenueActualArray[context.dataIndex]}%` // Show positive value in data labels
                                }
                            },
                        },
                    ]
                },
                options: {
                    layout: {
                    padding: {
                        right: paddingRight,
                        left: paddingLeft,
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            label: function (tooltipItems, data) {
                                let value
                                if (props.sales === true) {
                                    // value = `${actualArray[tooltipItems.index] * -1} | ${targetArray[tooltipItems.index] * -1}`
                                    value = `${salesActualArray[tooltipItems.index]}`
                                } else if (props.revenue === true) {
                                    // value = `${salesActualArray[tooltipItems.index]} | ${targetArray[tooltipItems.index]}`
                                    value = `${revenueActualArray[tooltipItems.index]}`
                                }
                                return value
                            }
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                display: true, // Hide the X-axis
                                ticks: {
                                    beginAtZero: true,
                                    display: false, // Hide the X-axis labels
                                },
                                // gridLines: {
                                //     display: true, // Hide the grid lines on the X-axis
                                // }
                                // gridLines: {
                                //     display: true,
                                //     color: '#6f6f70',
                                //     // tickMarkLength: 0,
                                //     zeroLineColor: '#6f6f70',
                                //     zeroLineWidth: 0.01,
                                //     // zeroLineBorderDash: [1],
                                // },
                            },
                        ],
                        yAxes: [
                            {
                                id: 'bar-x-axis1',
                                display: true,
                                offset: true,
                                type: 'category',
                                // gridLines: {
                                //     display: true,
                                //     color: '#6f6f70',
                                //     tickMarkLength: 0,
                                //     zeroLineColor: '#6f6f70',
                                //     zeroLineWidth: 0.01,
                                //     zeroLineBorderDash: [3],
                                // },
                                ticks: {
                                    display: false,
                                    padding: 9,
                                    fontColor: '#dee5ef',
                                    fontSize: '22'
                                },
                            },
                            {
                                display: false,
                                offset: true,
                                id: 'bar-x-axis2',
                                type: 'category',
                                gridLines: {
                                    display: true,
                                    color: 'grey',
                                },
                                ticks: {
                                    padding: 7,
                                    fontColor: '#dee5ef',
                                    fontSize: '22'
                                },
                            },
                        ]
                    },
                    legend: {
                        display: false
                    }
                }
            }

            if (chartContainer && chartContainer.current) {
                const newChartInstance = new Chart(ctx, chartConfig)
                setChartInstance(newChartInstance)
            }
        } catch (err) {
            if (err) setError("errorcaught");
        }
    }, [chartContainer, props.datas]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <ErrorBoundary fallback='Something went wrong, please reload'>
            <canvas ref={chartContainer} />
        </ErrorBoundary>
    )
}

export default ProductWiseBarGraph
