import React, { useRef, useEffect, useState } from 'react'
import Chart from 'chart.js'
import 'chartjs-plugin-datalabels'
import { numberFormatNoSeparator } from '../../utility/utility'
import styles from './OrgHsse.module.scss'

Chart.defaults.global.defaultFontFamily = 'Titillium Web'

const HsseKpiGraph = (props) => {
    const chartContainer = useRef('barGraph')
    const [chartInstance, setChartInstance] = useState(null)

    useEffect(() => {
        let xTicks = ['Retail', 'LPG', 'Lubes', 'Aviation']
        
        if (chartInstance) {
            chartInstance.destroy()
        }
        let incidentData = []
        
        props.hsseData.forEach(hsseValues => {        
            if(hsseValues.kpi_name === 'Incidents Reported'){
                if(hsseValues.bu === 'RETAIL'){
                    incidentData[0] = parseFloat(hsseValues.actual).toFixed(2)
                }
                if(hsseValues.bu === 'LPG'){
                    incidentData[1] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'LUBRICANTS'){
                    incidentData[2] = parseFloat(hsseValues.actual).toFixed(2)
                }if(hsseValues.bu === 'AVIATION'){
                    incidentData[3] = parseFloat(hsseValues.actual).toFixed(2)
                // }if(hsseValues.bu === 'PIPELINE'){
                //     incidentData[4] = parseFloat(hsseValues.actual).toFixed(2)
                }
                // if(hsseValues.bu === 'I&C'){
                //     incidentData[4] = parseFloat(hsseValues.actual).toFixed(2)
                // }
            }
        })

        const ctx = chartContainer.current.getContext('2d')
        let gradient = ctx.createLinearGradient(0, 0, 0, 450)
        // gradient.addColorStop(1, '#2281e8')
        // gradient.addColorStop(0, '#197ae3')
        gradient.addColorStop(1, '#ffbf45')
        gradient.addColorStop(0, '#ffbb3b')

        const chartConfig = {
            type: 'bar',
            data: {
                labels: xTicks,
                datasets: [
                    {
                        label: 'Incident reported',
                        data: incidentData,
                        categoryPercentage: 0.8,
                        barPercentage: 0.5,
                        backgroundColor: gradient,
                        borderWidth: 0,
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end',
                            color: '#ffffff',
                            font: {
                                size: 14
                            },
                            formatter: function (value) {
                                return (value != 0) ? numberFormatNoSeparator(value) : ''
                            }
                        },
                        order : 2
                    },
                ]
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                annotation: {
                },
                elements : {
                    line : {
                        tension : 0.4
                    }
                },
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            type: 'category',
                            display: true,
                            offset: true,
                            gridLines: {
                                color: 'transparent',
                                offsetGidLines: true,
                                tickMarkLength: 0,
                                borderDash: [3],
                                lineWidth: 3,
                                zeroLineBorderDash: [3]
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ],

                    yAxes: [
                        {
                            stacked: true,
                            ticks: {
                                display: true,
                                padding: 20,
                                fontColor: '#dee5ef',
                                fontSize: '20',
                                maxTicksLimit : 5
                            },
                            gridLines: {
                                color: '#2f3b51',
                                tickMarkLength: 0,
                                zeroLineColor: '#67758d',
                                zeroLineWidth: 1,
                                lineWidth: 3,
                            },
                            scaleLabel: {
                                display: true,
                                // labelString: unit[0],
                                fontColor: '#dee5ef',
                                fontSize: '20',
                            },
                        },
                    ]
                },
                legend: {
                    display: false
                },
            }
        }
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig)
            setChartInstance(newChartInstance)
        }
    }, [props.graphData, props.publicVar, props.privateVar, props.bothVar, props.hsseData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`flex-grow-1 ${styles.graphContainer}`}>
        <div
            className={`d-flex justify-content-between align-items-center ${styles.legendsContainer}`}
        >
                    <div
                        className={`d-flex align-items-center justify-content-around p-2 ${styles.tabLabelsContainer}`}
                    >
                        <div className="d-flex align-items-center">
                            <span className={`mr-2 ${styles.incidentDot}`}></span>Incident Reported &#40; Nos. &#41; 
                        </div>
                    </div>            
        </div>
        <div className={`my-4 ${styles.grmGraph}`}>
            {/* <canvas ref={chartContainer} /> */}
            <canvas
            ref={chartContainer}
        />   
        </div>
    </div>
    )
}
export default HsseKpiGraph