import React, { useState, useEffect, useCallback } from 'react'
import styles from './PerformanceAndProductivity.module.scss'
import ExpandIcon from '../../../assets/images/expandIcon.png'
import OrgListPP from './OrgListPP'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchPerformanceProductivity, fetchupdatedStatus } from '../../../services/Org'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { QuarterlyDataNotFound } from '../../DataNotFound/quarterlydatanotfound'
import greenTick from '../../../assets/images/greenTick.png'
import {isEmpty} from 'lodash'
import "../../../Common.scss"

const PerformanceAndProductivity = (props) => {
    const [loading, setLoading] = useState(true)
    const [ope, setope] = useState([])
    const [rpe, setrpe] = useState([])
    const [epe, setepe] = useState([])
    const [quarter, setquarter] = useState(0)
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const [updatedStatus, setUpdatedStatus] = useState('')

    const fullScrn = () => {
        if (attr === 'handleenter') {
            setattr('handleexit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleexit') {
            setattr('handleenter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {      
            let revenueFromOperationsUpdated = false;
            let ebitdaUpdated = false;
            response.forEach(res => {
                if (res.kpi_name === "revenue from operations" && res.status === "Updated" && res.entity === "Finance") {
                    revenueFromOperationsUpdated = true;
                }
                if (res.kpi_name === "EBITDA" && res.status === "Updated" && res.entity === "Finance") {
                    ebitdaUpdated = true;
                }
            });
            if (revenueFromOperationsUpdated && ebitdaUpdated) {
                setUpdatedStatus("Updated");
            } else {
                setUpdatedStatus("Not Found")
            }
            // if (!isEmpty(response)) {
            //     response.filter((res) => res.entity === "FINANCE" && res.kpi_name === "revenue from operations" && res.kpi_name === "EBITDA" ? setUpdatedStatus(res.status) : false);
            // } else {
            //     setUpdatedStatus("Not Found")
            // }
        })
        .catch(error => console.debug(error))
        function getPerformanceProductivity() {
            fetchPerformanceProductivity(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length === 0) {
                    setLoading(true)
                    setquarter(0)
                } else {
                    let opearray = []
                    let rpearray = []
                    let epearray = []
                    data.map(getprldata => {
                        if (getprldata.sub_kpi_type === 'rpe') {
                            rpearray.push(getprldata)
                            setrpe(rpearray[0])
                        }
                        if (getprldata.sub_kpi_type === 'ope') {
                            opearray.push(getprldata)
                            setope(opearray[0])
                        }
                        if (getprldata.sub_kpi_type === 'epe') {
                            epearray.push(getprldata)
                            setepe(epearray[0])
                        }
                    })
                    let lastQuarter = new Date(data[0].date).getMonth() + 1
                    let quarterData
                    switch (lastQuarter) {
                        case 6:
                            quarterData = 1
                            break
                        case 9:
                            quarterData = 2
                            break
                        case 12:
                            quarterData = 3
                            break
                        case 3:
                            quarterData = 4
                            break
                        default:
                            break
                    }
                    setquarter(quarterData)
                    setLoading(false)
                }
            }).catch(error => console.debug(error))
        }
        getPerformanceProductivity()
    }, [props.dropdown, props.year])
    return (
        <FullScreen handle={handle} onChange={reportChange}>
            <div className={`${styles.PerformanceAndProductivityContainer}`}>
                <div className={`d-flex align-items-center justify-content-between ${styles.PerformanceAndProductivityHeader}`}>
                    <span>Performance & Productivity (Quarter {quarter}) - Quarterly</span>
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                    <img src={ExpandIcon} alt="Expand Employee Data And Recruitment" id="fullSrnpp" data-fs="handleenter" onClick={fullScrn}></img>
                </div>
                {loading
                    ?
                    // <NoDataFound />
                    <QuarterlyDataNotFound />
                    :
                    <OrgListPP rpe={rpe} ope={ope} epe={epe}></OrgListPP>
                }
            </div>
        </FullScreen>
    )
}
export default PerformanceAndProductivity