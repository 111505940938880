import React, { useState, useEffect, useCallback } from 'react'
import styles from './BalanceSheets.module.scss'
import ExpandIcon from '../../../assets/images/expandIcon.png'
import OrgListTile from '../../OrgListTile/OrgListTile'
import BalanceSheetGraph from './BalanceSheetGraph'
import DeltaValuesOrg from '../../DeltaValuesOrg/DeltaValuesOrg'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchBalanceSheetCapital, fetchBalancesheetDm, fetchBalanceSheetOil, fetchupdatedStatus } from '../../../services/Org'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import { numberFormatEnIn } from '../../../utility/utility'
import { QuarterlyDataNotFound } from '../../DataNotFound/quarterlydatanotfound'
import { isEmpty } from 'lodash'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"


const BalanceSheets = (props) => {
    const [bsdata, setbsdata] = useState([])
    const [totaldebt, settotaldebt] = useState([])
    const [totalmat, settotalmat] = useState([])
    const [oilbond, setoilbond] = useState([])
    const [captl, setcaptl] = useState([])
    const [debQuaterYear, setdebQuaterYear] = useState([])
    const [matQuaterYear, setmatQuaterYear] = useState([])
    const [loading, setLoading] = useState(true)
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleenter')
    const [updatedStatus, setUpdatedStatus] = useState('')
    const fullScrn = () => {
        if (attr === 'handleenter') {
            setattr('handleexit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleexit') {
            setattr('handleenter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]);// eslint-disable-next-line react-hooks/exhaustive-deps
    const getCurrentQuarter = (oilbond, captl) => {
        try {
            let getdate = [oilbond.date, captl.date]
            let getlastesdate = getdate.reduce((a,b) => {return a > b ? a : b }, 0)
            let lastQuarter = new Date(getlastesdate).getMonth() + 1
            let quarterData
                switch (lastQuarter) {
                    case 6:
                        quarterData = 1
                        break
                    case 9:
                        quarterData = 2
                        break
                    case 12:
                        quarterData = 3
                        break
                    case 3:
                        quarterData = 4
                        break
                    default:
                        quarterData = 0
                        break
                }
                return quarterData
        } catch (error) {
            console.debug(error)
        }
    }
    useEffect(() => {
        fetchupdatedStatus(props.year)
                .then(response => {
                    let salesUpdated = false;
                    let maturitiesUpdated = false;
                    response.forEach(res => {
                        if (res.kpi_name === "debt" && res.status === "Updated" && res.entity === "Finance") {
                            salesUpdated = true;
                        }
                        if (res.kpi_name === "maturities" && res.status === "Updated" && res.entity === "Finance") {
                            maturitiesUpdated = true;
                        }
                    });
                    if (salesUpdated && maturitiesUpdated) {
                        setUpdatedStatus("Updated");
                    } else {
                        setUpdatedStatus("Not Found")
                    }
                })
                .catch(error => console.debug(error))
        function getBalancesheet() {
            let oilarray = []
            let captlarray = []
            fetchBalanceSheetOil(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length === 0) {
                    setLoading(true)
                    setoilbond({value: 0,quater: 0,year: 0})
                } else {
                    data.map(getbsval => {
                        oilarray.push({
                            value: getbsval.value,
                            quater: getbsval.quaterly,
                            year: getbsval.yearly,
                            date: getbsval.date
                        })
                        setoilbond(oilarray[0])
                    })
                    setLoading(false)
                }
            }).catch(error => console.debug(error))
            fetchBalanceSheetCapital(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length === 0) {
                    setLoading(true)
                    setcaptl({value: 0,quater: 0,year: 0})
                } else {
                    data.map(getbsval => {
                        captlarray.push({
                            value: getbsval.value,
                            quater: getbsval.quaterly,
                            year: getbsval.yearly,
                            date: getbsval.date
                        })
                        setcaptl(captlarray[0])
                    })
                    setLoading(false)
                }
            }).catch(error => console.debug(error))
        }
        getBalancesheet()
        function getBalancesheetdm() {
            let totaldebt = []
            let totalmat = []
            let totaldebQuater = []
            let totaldebYear = []
            let totalmatQuater = []
            let totalmatYear = []
            fetchBalancesheetDm(props.dropdown.toLowerCase(), props.year).then(data => {
                if (data.length === 0) {
                    setLoading(true)
                } else {
                    setbsdata(data)
                    data.map(gettotal => {
                        if (gettotal.sub_kpi_type === 'debt') {
                            totaldebt.push(parseInt(gettotal.value))
                            totaldebQuater.push(gettotal.quaterly)
                            totaldebYear.push(gettotal.yearly)
                            let totald = totaldebt[0]
                            let totaldq = totaldebQuater[0]
                            let totaldy = totaldebYear[0]
                            settotaldebt(totald)
                            setdebQuaterYear({
                                quater: totaldq,
                                year: totaldy
                            })
                        }
                        if (gettotal.sub_kpi_type === 'maturities') {
                            totalmat.push(parseInt(gettotal.value))
                            totalmatQuater.push(gettotal.quaterly)
                            totalmatYear.push(gettotal.yearly)
                            let totalm = totalmat[0]
                            let totalmq = totalmatQuater[0]
                            let totalmy = totalmatYear[0]
                            settotalmat(totalm)
                            setmatQuaterYear({
                                quater: totalmq,
                                year: totalmy
                            })
                        }
                    })
                    setLoading(false)
                }
            }).catch(error => console.debug(error))
        }
        getBalancesheetdm()
    }, [props.dropdown, props.year])
    return (
        <FullScreen handle={handle} onChange={reportChange}>
            <div className={`${styles.BalanceSheetsContainer}`}>
                <div className={`d-flex align-items-center justify-content-between ${styles.BalanceSheetsHeader}`}>
                    <span>Balance Sheets (Quarter {getCurrentQuarter(oilbond, captl)}) - Quarterly</span>
                    {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                        <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                    </div>}
                    <img src={ExpandIcon} alt="Expand BalanceSheets" id="fullSrnbs" data-fs="handleenter" onClick={fullScrn}></img>
                </div>
                {loading ?
                    // <NoDataFound />
                    <QuarterlyDataNotFound/>
                    :
                    <div className={`${styles.BalanceSheetsGraphContainer}`}>
                        <div className={`pt-2 ${styles.headerContainer}`}>
                            <div className={`${styles.headerRight}`}>
                                <span>Total Debt & Current Maturities</span>
                            </div>
                            <div className={`${styles.headerLabels} mt-2 d-flex align-items-center `}>
                                <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.HpclDot}`}></span>Total Debt: <span style={{ color: '#fff', fontWeight: 'normal', marginLeft: '5px' }}>INR {numberFormatEnIn(parseFloat(totaldebt).toFixed(2))} Cr </span></div>
                                <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.IoclDot}`}></span>Current Maturities: <span style={{ color: '#fff', fontWeight: 'normal', marginLeft: '5px' }}>INR {numberFormatEnIn(parseFloat(totalmat).toFixed(2))} Cr </span></div>
                            </div>
                            <div className={'d-flex mt-2'}>
                                <div className={'d-flex mr-5'}><DeltaValuesOrg quater={debQuaterYear.quater !== null && parseFloat(debQuaterYear.quater).toFixed(2)} year={debQuaterYear.year !== null && parseFloat(debQuaterYear.year).toFixed(2)}></DeltaValuesOrg></div>
                                <div className={'d-flex mr-5'}><DeltaValuesOrg quater={matQuaterYear.quater !== null && parseFloat(matQuaterYear.quater).toFixed(2)} year={matQuaterYear.year !== null && parseFloat(matQuaterYear.year).toFixed(2)}></DeltaValuesOrg></div>
                            </div>
                        </div>
                        <BalanceSheetGraph graphData={bsdata}></BalanceSheetGraph>
                    </div>
                }
                {loading ?
                    <NoDataFound />
                    :
                    <OrgListTile oilbond={oilbond} captl={captl}></OrgListTile>
                }
            </div>
        </FullScreen>
    )
}
export default BalanceSheets