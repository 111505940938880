import React, { useState, useEffect, useCallback } from 'react'
import expandIcon from '../../../assets/images/expandIcon.png'
import styles from './BuEthanolBlending.module.scss'
import BuEthanolBlendGraph from './BuEthanolBlendGraph'
import BuEthanolBlendBarGraph from './BuEthanolBlendBarGraph'
import { Spinner } from 'react-bootstrap'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { fetchRetailEthanolBlending, fetchupdatedStatus } from '../../../services/bu'
import { every, isEmpty, last } from 'lodash'
import { NoDataFound } from '../../DataNotFound/nodatafound'
import greenTick from '../../../assets/images/greenTick.png'
import "../../../Common.scss"

const BuEthanolBlending = (props) => {
    const [isNoDataAvailable, setIsNoDataAvailable] = useState(true)
    const [graphData, setGraphData] = useState([])
    const handle = useFullScreenHandle()
    const [attr, setattr] = useState('handleEnter')
    const [updatedStatus, setUpdatedStatus] = useState('')
    const fullScrn = () => {
        if (attr === 'handleEnter') {
            setattr('handleExit')
            handle.enter()
            document.body.style.zoom = (window.innerWidth / window.outerWidth)
        } else if (attr === 'handleExit') {
            setattr('handleEnter')
            handle.exit()
            document.body.style.zoom = 0
        }
    }
    const reportChange = useCallback((state) => {
        try {
            if (state === false) {
                document.body.style.zoom = 0
            }
        } catch (exception) {
            console.debug(exception)
        }
    }, [handle]); // eslint-disable-next-line react-hooks/exhaustive-deps

    const fetchAndPrepareGraphData = async () => {
        const kpiTypes = ['qty_natl', 'qty_buom']
        const financialYear = new Date().getMonth() < 9 ? props.year - 1 : props.year;
        fetchRetailEthanolBlending('qty_buom', financialYear)
            .then((response) => {
                const data = response
                setGraphData(response.data)
                setIsNoDataAvailable(false)
                if (data.data.length === 0) {
                    setIsNoDataAvailable(true)
                    return last(data)
                }
            })
            .catch((error) => {
                console.debug(error)
            })

        // const [natl, buom] = await Promise.all(kpiTypes.map(mapEachFetch))

        // if (every([natl, buom], each => isEmpty(each))) {
        //     setIsNoDataAvailable(true)
        // } else {
        //     setGraphData({
        //         natl, buom
        //     })
        //     setIsNoDataAvailable(false)
        // }
    }

    useEffect(() => {
        fetchupdatedStatus(props.year)
        .then(response => {
            // console.log(response)
            if (!isEmpty(response)) {
            response.filter((res) => res.entity === "retail" && res.kpi_name === "Ethanol Blending" ? setUpdatedStatus(res.status) : false);
            }else{
                setUpdatedStatus("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchAndPrepareGraphData()
    }, [props.activeTab, props.year])

    return (
        <div className={`${styles.BuTransportationContainer}`}>
            <FullScreen handle={handle} onChange={reportChange}>
                <div className={`d-flex align-items-center justify-content-between ${styles.headerContainer}`}>
                    <div className={`${styles.headerLeft}`}>
                        <div className={`${styles.heading}`}><span>Ethanol Blending (MTD) - Daily</span>
                            {(updatedStatus === "Updated") && <div className={`${styles.statusUpdatedImg}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                        </div>
                        <div className={'row pt-2'}>
                        <div className={`d-flex align-items-center ${styles.headerLabels}`}>
                            <div className="col-6 d-flex align-items-center"><span className={`mr-2 ${styles.MSsale}`}></span>MS Sale (TKL)</div>
                            <div className="col-9 d-flex align-items-center"><span className={`mr-2 ${styles.Ethanolsale}`}></span>Ethanol Sale (TKL)</div>
                            {/* <div className="d-flex mr-4 align-items-center "><span className={`mr-2 ${styles.targetDot}`}></span></div> */}
                        </div>
                        {/* <div className={`col-4 ${styles.headerLabelsTarget}`}>
                        <span>---- BPCL Target</span>
                    </div> */}
                    </div>
                    </div>
                </div>
                <div className={`d-flex ${styles.graphContainer}`}>
                    {
                        isNoDataAvailable ?
                            <NoDataFound height={507} />
                            :
                            <>
                                {
                                    graphData ?
                                        <BuEthanolBlendBarGraph graphData={graphData}></BuEthanolBlendBarGraph>
                                        :
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                }
                                <div className={styles.expandIcon}>
                                    <img src={expandIcon} alt='Expand Icon' id="fullEb" data-fs="handleenter" onClick={fullScrn}></img>
                                </div>
                            </>
                    }
                </div>
            </FullScreen>
        </div>
    )
}
export default BuEthanolBlending